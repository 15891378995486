import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import headerStyles from "./header.module.scss"
import monogram from "../../images/monogram.svg"

const Header = ({ siteTitle, headerDescription }) => (
  <header className={headerStyles.header}>
    <div className={headerStyles.header__hgroup}>
      <h1>
        <Link to="/">
          {siteTitle}
        </Link>
      </h1>
      <p className={headerStyles.header__sub}>
        Visual Designer <br></br>
        User Experience Designer
      </p>
    </div>
    {headerDescription 
      ? <p className={headerStyles.header__description}>{headerDescription}</p>
      : ""
    }
    <Link className={headerStyles.header__monogram} to="/">
      <img src={monogram} alt="DJN monogram"/>
    </Link>
    <nav className={headerStyles.header__nav}>
      <ul>
        <li>
          <Link to="/">
            Projects
          </Link>
        </li>
        <li>
          <Link to="/about/">
            About
          </Link>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

