import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./footer.scss"
import monogram from "../../images/monogram.svg"

const Footer = ({ siteTitle }) => (
  <footer className="footer">
    <h1 className="footer__hed">
        <Link to="/">
            {siteTitle}
        </Link>
    </h1>
    <ul className="footer__links">
        <li>
            <a href="https://www.instagram.com/dj_nol/" target="_blank">
                instagram
            </a>
        </li>
        <li>
            <a href="https://www.linkedin.com/in/dan-nolan-designer/" target="_blank">
                linkedin
            </a>
        </li>
        <li>
            <a href="mailto:dan.nolan28@gmail.com">
                email
            </a>
            <span className="footer__email">
                dan.nolan28@gmail.com
            </span>
        </li>
    </ul>
    <Link className="footer__monogram" to="/">
      <img src={monogram} alt="DJN monogram"/>
    </Link>
    <nav className="footer__nav">
      <ul>
        <li>
          <Link to="/">
            Projects
          </Link>
        </li>
        <li>
          <Link to="/about/">
            About
          </Link>
        </li>
      </ul>
    </nav>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer