/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "../../styles/global.scss"
import layoutStyles from "./layout.module.scss"
import Header from "../header/header"
import Footer from "../footer/footer"
import SEO from "../seo.js"

const Layout = ({ children, customClass, includeHeader }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          siteTitle
          subTitle
          description
        }
      }
    }
  `)
  const pageUrl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>
      <div className={`${layoutStyles.layout} ${customClass}`}>
        <SEO 
          title={`${data.site.siteMetadata.siteTitle} | ${data.site.siteMetadata.subTitle}`}
          description={data.site.siteMetadata.description}
          //ogImage={heroImage.publicURL}
          pageUrl={pageUrl}
        />
        {!includeHeader 
          ? <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
          : ""
        }
        <main className="layout__grid">{children}</main>
        <Footer siteTitle={data.site.siteMetadata?.title || `Title`} />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
