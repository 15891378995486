/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, ogImage, pageUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            subTitle
            description
            author
          }
        }
      }
    `
  )

  //const metaDescription = description || site.siteMetadata.description
  //const pageTest = title || site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{ lang: `en` }}
      //titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
    <title>{title}</title>
    <meta
      name='description'
      content={description || site.siteMetadata.description}
    />
    <meta property="og:title" content={title} />
    <meta property="og:url" content={pageUrl} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={ogImage} />
    <meta property="og:type" content="website" />

    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:creator" content={site.siteMetadata.author} />
    <meta name="twitter:image" content={ogImage}></meta>

    <link rel='canonical' href={pageUrl} />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
